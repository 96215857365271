<template>
  <div class="w-full">
    <label
      v-if="noLabel && !hideLabel"
      :for="id"
      :class="[disabled ? 'text-gray-500' : 'text-secondary ']"
      class="h-min-5 mb-1 flex items-center gap-2 px-4 text-base font-medium text-secondary relative"
    >
      {{ $t(label || '') }}
      <sup v-if="required" class="text-danger">*</sup>
      <span v-if="tooltip" class="flex-1 flex justify-end">
        <BaseIcon
          v-tippy="tooltip"
          outlined
          name="info"
          size="24"
          class="text-gray-300 hover:text-primary-blue dark:text-secondary"
        />
      </span>
    </label>
    <div class="relative group transition">
      <div
        v-if="icon"
        class="absolute left-4 top-0 flex h-full items-center gap-2 text-black"
      >
        <slot name="left-icon" />
        <BaseIcon :name="icon" size="20"></BaseIcon>
      </div>
      <div
        v-if="!noLabel && !hideLabel"
        :class="{
          '!items-start': isHasValue,
          'group-hover:items-start': !disabled,
        }"
        class="absolute inset-0 flex items-center pointer-events-none transition ease-in-out delay-150"
      >
        <label
          v-if="!noLabel"
          :for="id"
          class="pl-4 font-medium transition"
          :class="[
            requiredData,
            expectLabel || isHasValue ? 'text-xs pt-1' : 'text-base',
            disabled
              ? 'text-gray-500'
              : 'text-secondary group-hover:text-xs group-hover:pt-1',
          ]"
        >
          {{ $t(label || '') }}
        </label>
      </div>
      <input
        :id="id"
        v-model="inputValue"
        :type="inputType"
        v-bind="inputSetting"
        :name="name"
        :aria-label="name"
        :placeholder="placeholder"
        :class="[
          errorMessage
            ? 'border-danger/30 bg-danger/5 text-danger dark:border-danger dark:text-danger'
            : disabled
              ? 'border-blue-100 bg-gray-100 text-black'
              : 'border-gray-100 bg-gray-100 text-black',
          dense ? 'py-2' : 'py-5',
          icon ? 'pl-12' : 'pl-4',
          {
            'placeholder-opacity-0 group-hover:placeholder-opacity-100':
              isHasValue,
          },
          inputValue ? '' : 'placeholder-opacity-100',
        ]"
        class="block w-full rounded-2xl border-2 pr-3 text-base outline-none ring-2 ring-transparent placeholder-gray-500 focus:border-primary focus:ring-primary/30"
        :disabled="disabled"
        @input="onChange"
      />
      <div
        v-if="!alert"
        class="absolute right-4 top-0 flex h-full items-center justify-center gap-2 pointer-events-none"
      >
        <slot name="right-icon" />
        <BaseIcon
          v-if="disabled && !errorMessage"
          outlined
          name="edit_off"
          size="24"
          class="text-gray-400 dark:text-gray-400 print:hidden"
        ></BaseIcon>
        <slot />
      </div>
    </div>
    <template v-if="alert">
      <BaseAlert
        :show="!!errorMessage"
        icon="info"
        outlined
        class="field--error-alert"
        type="danger"
      >
        {{ $t(errorMessage || '') }}
      </BaseAlert>
    </template>
    <div v-else-if="!noAlert && errorMessage" class="h-5 pl-4 mt-1">
      <p class="text-xs font-medium text-danger dark:text-danger">
        {{ $t(errorMessage || '') }}
      </p>
    </div>
  </div>
</template>

<script>
  import { useField } from 'vee-validate';
  import { ref, computed } from 'vue';
  import BaseIcon from '@/components/UI/BaseIcon.vue';
  import BaseAlert from '@/components/UI/BaseAlert.vue';
  import isNumber from 'lodash/isNumber.js';

  export default {
    components: {
      BaseAlert,
      BaseIcon,
      // VTooltip
    },
    props: {
      label: String,
      name: String,
      type: {
        type: String,
        default: 'text',
      },
      id: String,
      placeholder: String,
      modelValue: [String, Number],
      icon: {
        type: String,
        default: '',
      },
      errors: {
        type: Object,
        default: () => ({}),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      dark: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      maska: {
        type: String,
        default: '',
      },
      tooltip: {
        type: [String],
        default: null,
      },
      noLabel: {
        type: Boolean,
        default: false,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      alert: {
        type: Boolean,
        default: false,
      },
      noAlert: {
        type: Boolean,
        default: false,
      },
      hideCheckIcon: Boolean,
      min: {
        type: Number,
        default: 0,
      },
      max: {
        type: Number,
        default: null,
      },
      step: {
        type: [Number, String],
        default: 1,
      },
      enabledControlled: Boolean,
      syncVModel: Boolean,
      rules: Object,
    },
    emits: ['update:modelValue'],

    setup(props, { emit }) {
      const isShow = ref(false);
      const inputType = computed(() => {
        if (isShow.value) {
          return 'text';
        }
        return props.type;
      });

      const {
        value: inputValue,
        errorMessage,
        handleChange,
        meta,
      } = useField(() => props.name || '', props.rules || undefined, {
        initialValue: props.modelValue,
        type: inputType.value,
        controlled: !props.enabledControlled,
        syncVModel: props.syncVModel,
      });

      const onChange = ({ target }) => {
        let vInput = target.value;
        if (props.type === 'number' && target.value !== '') {
          vInput = Number(vInput);
        }
        handleChange(vInput);
        emit('update:modelValue', vInput);
      };

      const expectLabel = computed(() => ['time', 'date'].includes(props.type));

      const isHasValue = computed(() => {
        return (
          !!props.placeholder ||
          expectLabel.value ||
          isNumber(inputValue.value) ||
          !!inputValue.value?.length
        );
      });

      const inputPattern = computed(() => {
        if (props.type === 'time') {
          return '[0-9]{2}:[0-9]{2}';
        }
        return false;
      });

      const inputStep = computed(() => {
        if (props.type === 'number') {
          return Number(props.step);
        }
        return false;
      });

      const inputSetting = computed(() => {
        const sett = {};
        if (props.type === 'number') {
          sett['step'] = props.step;
          sett['min'] = props.min;

          if (props.max) {
            sett['max'] = props.max;
          }
        }
        if (props.type === 'time') {
          sett['step'] = '[0-9]{2}:[0-9]{2}';
        }
        return sett;
      });

      const requiredData = computed(() => {
        if (props.required) {
          return 'after:content-["*"] after:ml-0.5 after:absolute after:text-danger';
        }
        return '';
      });

      return {
        inputType,
        isShow,
        errorMessage,
        requiredData,
        inputValue,
        onChange,
        meta,
        isHasValue,
        expectLabel,
        inputPattern,
        inputStep,
        inputSetting,
      };
    },
  };
</script>
